body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  margin-top: 70px;
  padding: 0px 10px 10px 10px;
  min-height: 90vh !important;
}

.ant-layout-footer {
  background-color: #001529 !important;
  color: rgba(255, 255, 255, 0.65) !important;
  width: 100%;
}

.table-action-btn {
  margin-bottom: 10px;
  text-align: right !important;
}

.full-height {
  height: 100%;
}

.space-top {
  margin-top: 20px !important;
}

.space-top-10 {
  margin-top: 10px !important;
}

.draggable-row {
  cursor: move;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ant-upload-list-item-name {
  color: #1890ff;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  cursor: default !important;
}

.ant-modal.customModal {
  display: flex !important;
  width: 980px !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 6px 6px !important;
}

.ant-modal.editModal {
  width: 980px !important;
}

.ant-modal-confirm-content {
  font-size: 16px !important;
  font-weight: 500;
}

.ant-modal-confirm-title {
  font-size: 20px !important;
}
.ant-modal {
  top: 30px !important;
  width: 550px !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td.editConfigModal {
  position: relative;
  padding: 8px 8px !important;
}

#drag.ant-table-tbody .draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  font-size: 1.2rem;
  transform: rotate(90deg);
}

.gu-mirror {
  background-color: rgba(16, 142, 233, 0.15);
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.ant-table.draggable {
  font-size: 13px;
}

.ant-modal-body {
  padding-bottom: 0px !important;
}
